import React from 'react';
import Layout from '../components/layout/Layout';
import PDFViewer from '../components/PDFViewer';
import { Section, SectionTitle } from '../components/Section';
import lightFeatherCatalog from '../pdf/LightFeather-GSA-Schedule-70-Catalog.pdf';
import { Helmet } from 'react-helmet';

const LightFeatherLLCCatalog = () => (
  <Layout>
    <Helmet>
      <meta charSet='utf-8' />
      <title>LightFeather GSA MAS IT Catalog</title>
    </Helmet>
    <Section>
      <SectionTitle headingLevel='h1' className='text-blue'>
        LightFeather GSA MAS IT Catalog
      </SectionTitle>
      <PDFViewer pdf={lightFeatherCatalog} pdfTitle='LightFeather GSA MAS IT Catalog' />
    </Section>
  </Layout>
);

export default LightFeatherLLCCatalog;
