import React from 'react';
import PDFIcon from '../svg/PDFIcon';

const PDFViewer = ({ pdf, pdfTitle }) => {
  return (
    <div className='container mx-auto'>
      <div className='grid grid-cols-1 px-5'>
        <div className='flex mx-auto my-5'>
          You can view the PDF file by clicking the icon below.
        </div>
        <a
          className='flex justify-center items-center border-2 rounded-xl p-2 mx-auto max-w-400-px shadow-md'
          href={pdf}
          target='_blank'
          rel='noreferrer'
        >
          <PDFIcon />
          <div className='text-center font-semibold w-2/3 px-3'>{pdfTitle}</div>
        </a>
      </div>
    </div>
  );
};

export default PDFViewer;
